import React from 'react';
import { Fade } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { DetailInventoryData } from 'utils/interfaces/Log';
import { Table } from 'components/Tools/Table';
import { TableContainer } from 'app/WMS/styles/styles';
import { IInventoryDetailsTableProps } from '../../../types';

const InventoryDetailsTable: React.FC<IInventoryDetailsTableProps> = ({
  rows,
}) => {
  const { t } = useTranslation();
  return (
    <Fade in timeout={1000} unmountOnExit>
      <TableContainer>
        <Table<DetailInventoryData>
          columns={[
            {
              title: String(t('screens.reportsStock.address')),
              orderable: true,
              display: 'notEmpty',
              type: 'string',
              props: ['descricaoEndereco'],
              cssProps: {
                width: '1%',
              },
              cssTitle: {
                width: '1%',
              },
            },
            {
              title: String(t('screens.commom.pallet')),
              type: 'string',
              orderable: true,
              display: 'notEmpty',
              props: ['descricaoPalete'],
              cssProps: {
                width: '1%',
              },
              cssTitle: {
                width: '1%',
              },
            },

            {
              title: String(t('screens.commom.box')),
              orderable: true,
              display: 'notEmpty',
              type: 'string',
              props: ['caixa'],
              cssProps: {
                width: '1%',
              },
              cssTitle: {
                width: '1%',
              },
            },
            {
              title: String(t('screens.reportsStock.codeRequest')),
              orderable: true,
              display: 'notEmpty',
              type: 'string',
              props: ['codigoPedido'],
              cssProps: {
                width: '1%',
              },
              cssTitle: {
                width: '1%',
              },
            },
            {
              title: 'EAN',
              orderable: true,
              display: 'notEmpty',
              type: 'string',
              props: ['ean'],
              cssProps: {
                width: '1%',
              },
              cssTitle: {
                width: '1%',
              },
            },
            {
              title: String(t('screens.commom.product')),
              orderable: true,
              display: 'notEmpty',
              type: 'string',
              props: ['produto'],
              cssProps: {
                width: '1%',
              },
              cssTitle: {
                width: '1%',
              },
            },
            {
              title: String(t('screens.reportsStock.descriptionProduct')),
              orderable: true,
              display: 'notEmpty',
              type: 'string',
              props: ['descricaoProduto'],
              cssProps: {
                width: '1%',
              },
              cssTitle: {
                width: '1%',
              },
            },
            {
              title: String(t('screens.pickingConference.bathModalTitle')),
              orderable: true,
              display: 'notEmpty',
              type: 'string',
              props: ['lote'],
              cssProps: {
                width: '1%',
              },
              cssTitle: {
                width: '1%',
              },
            },
            {
              title: String(t('screens.commom.unitMeasure')),
              orderable: true,
              type: 'string',
              props: ['unidadeMedida'],
              display: 'notEmpty',
              cssProps: {
                width: '1%',
              },
              cssTitle: {
                width: '1%',
              },
            },
            {
              title: String(t('screens.pickingConference.validity')),
              orderable: true,
              display: 'notEmpty',
              type: 'string',
              props: ['validade'],
              cssProps: {
                width: '1%',
              },
              cssTitle: {
                width: '1%',
              },
            },
            {
              title: String(t('screens.receivement.series')),
              orderable: true,
              display: 'notEmpty',
              type: 'string',
              props: ['serie'],
              cssProps: {
                width: '1%',
              },
              cssTitle: {
                width: '1%',
              },
            },
            {
              title: String(t('screens.commom.color')),
              type: 'string',
              orderable: true,
              display: 'notEmpty',
              props: ['cor'],
              cssProps: {
                width: '1%',
              },
              cssTitle: {
                width: '1%',
              },
            },
            {
              title: String(t('screens.commom.size')),
              type: 'string',
              orderable: true,
              display: 'notEmpty',
              props: ['tamanho'],
              cssProps: {
                width: '1%',
              },
              cssTitle: {
                width: '1%',
              },
            },
            {
              title: String(t('screens.commom.stock')),
              type: 'string',
              orderable: true,
              display: 'notEmpty',
              props: ['quantidade'],
              cssProps: {
                width: '1%',
              },
              cssTitle: {
                width: '1%',
              },
            },
            {
              title: String(t('screens.reportsStock.reserved')),
              orderable: true,
              display: 'notEmpty',
              type: 'string',
              props: ['reservado'],
              cssProps: {
                width: '1%',
              },
              cssTitle: {
                width: '1%',
              },
            },
            {
              title: String(t('screens.reportsStock.available')),
              orderable: true,
              display: 'notEmpty',
              type: 'string',
              props: ['disponivel'],
              cssProps: {
                width: '1%',
              },
              cssTitle: {
                width: '1%',
              },
            },
          ]}
          rows={rows}
          exportList
        />
      </TableContainer>
    </Fade>
  );
};
export default InventoryDetailsTable;
